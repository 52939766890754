import "lazysizes";
import {FloatLabels} from "@keodesign/float-labels";
import Splide from "@splidejs/splide";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {    
	let forms = document.querySelectorAll("form");
	if (forms){
		setupFloatLabel(forms);
	}
	setupSlideMenu();
	setupSlider(".slider");
	setupClickToContact();
};

export const setupFloatLabel =  (forms) => {
	forms.forEach(el => {
		el.classList.add("floatlabel-active");
		new FloatLabels(".float-wrap");
	});
};

export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			$("body").toggleClass("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			
			element.click(function(e) {
				e.stopPropagation();
				$("body").toggleClass("slide-menu-active");
			});
		}
	});
	const menu_links = [...document.querySelectorAll(".slide-menu a")];
	menu_links.forEach(el => {
		el.addEventListener("click", ()=> {
			$("body").toggleClass("slide-menu-active");
		});
	});

}

export function setupSlider(selector){
	var elms = document.querySelectorAll(selector);
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			rewind: true,
			pagination: false,
			arrows: false,
			autoplay: true,
			interval: 3000,
			perPage: 1,
			type: "loop",
			padding: "20%",
			speed: 1000,
			easing: "ease",
		}).mount();
	}
}

export function setupClickToContact(){
	var elms = document.querySelectorAll(".boat-card");
	for ( var i = 0; i < elms.length; i++ ) {
		elms[i].addEventListener("click", (e) => {
			var el = e.currentTarget;
			var title = el.dataset.title;
			var msg_field = document.getElementById("message");
			if (title){
				title = "[Enquiry for " + title + "]:";
				msg_field.value = title;
			}
			msg_field.scrollIntoView({behavior: "smooth", block: "start"});
			setTimeout(() => {  msg_field.focus(); }, 500);
			
		});
	}
}


//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});